.dashboard-home
    height: 100%
    display: flex
    flex-direction: column
    gap: 10px
    .ant-row
        height: 100%
        display: flex
        gap: 10px

        .ant-col
            background: #e2e9ffb6
            padding: 20px
            border-radius: 10px
    
    .row-1
        .col-1
            padding: 20px 20px 0 20px
            h1
                font-size: 20px
                font-weight: 500
                color: #0000009b
            h2
                font-size: 35px
                font-weight: 600
                color: #000000ca
                margin-bottom: 20px
            h3
                font-size: 20px
                font-weight: 500
                color: #0000009b

                @media (max-width: 768px)
                    font-size: 16px
            .users-info
                h4
                    font-size: 24px
                    font-weight: 400
                    color: #00000052
                    margin: 10px 0 0 0

                    span
                        font-weight: 500
                        color: #00000052
                        margin-left: 10px
                h5
                    font-size: 25px
                    font-weight: 500
                    color: #000c51ad
                    margin: 0 0 10px 0
                .ant-tag
                    padding: 10px
                    font-size: 14px
            .exam-countdown
                display: flex
                justify-content: space-between
                align-items: center
                margin: 10px 0 0 0
                h5
                    font-size: 20px
                    font-weight: 500
                    color: #000c51ad
                    margin: 0 0 10px 0
                    display: flex
                    align-items: center
                    gap: 0px
                    span
                        font-size: 25px
                        font-weight: 500
                        color: #b64141
                        margin-left: 10px
                h4
                    font-size: 18px
                    font-weight: 400
                    color: #00000052
                    margin: 0 0 10px 0

                    @media (max-width: 768px)
                        font-size: 14px

        .col-2
            padding: 5px 20px
            .ant-tag
                width: 100%
                padding: 6px
                margin-bottom: 10px
            .overview
                .quill
                    @media (max-width: 768px)
                        height: 100% !important
                .button-container
                    display: flex
                    justify-content: end
                    align-items: center
                    margin-bottom: 10px

                p
                    font-size: 14px
                    line-height: 1.8
                    color: #0000009b
                    margin: 0 0 10px 0
                    text-align: center


            .ant-carousel
                max-width: 600px
                margin: 0 auto

                span
                    height: 280px
                    border-radius: 10px
                    background: #cdd9ffdc
                    display: flex
                    justify-content: center
                    align-items: center

                    img
                        max-width: 100%
                        max-height: 100%
                        border-radius: 10px
    .row-2
        .col-2
            h1
                font-size: 20px
                font-weight: 500
                color: #0000009b
                margin-bottom: 10px
            li
                h4
                    font-size: 16px
                    font-weight: 500
                    color: #0000009b
                    margin: 0 0 10px 0

.overview-modal
    width: 60% !important