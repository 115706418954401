.youtube
    margin: 0
    padding: 0
    .video-filters
        @media (max-width: 768px)
            flex-direction: column !important
            margin-bottom: 10px !important
        .video-filters__left
            @media (max-width: 768px)
                flex-direction: column !important
                gap: 0px !important
            .ant-form-item
                @media (max-width: 768px)
                    width: 100% !important
                    padding: 0 !important
                    margin: 5px 0 !important
                    line-height: 0 !important
                .ant-form-item-row
                    @media (max-width: 768px)
                        width: 100% !important
        .ant-form-item
            @media (max-width: 768px)
                width: 100% !important
                padding: 0 !important
                margin: 0 !important
                line-height: 0 !important
                font-size: 0.3rem !important
            .ant-row
                .ant-col
                    @media (max-width: 768px)
                        padding: 0 !important
                        margin: 0 !important
                        line-height: 0 !important
                        font-size: 0.3rem !important
                    .ant-tag
                        @media (max-width: 768px)
                            font-size: 0.8rem !important
                            padding: 0.2rem 0.5rem !important
    .scrollableDiv
        margin: 0
        padding: 0
        height: calc( 100vh - 280px )
        overflow-y: scroll
        @media (max-width: 768px)
            height: calc( 100vh - 320px )


        /* Add custom scrollbar styles */
        &::-webkit-scrollbar 
            width: 8px
        
        &::-webkit-scrollbar-track 
            background-color: none
        
        &::-webkit-scrollbar-thumb 
            background-color: #555d74
            border-radius: 10px
        
        &::-webkit-scrollbar-thumb:hover 
            background-color: #242c41
        

        .ytblock
            padding: 1rem
            align-items: flex-start
            display: flex
            align-self: start
            margin-bottom: 1rem
            border-radius: 10px
            background: #F9F9F9
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1)
            transition: all 0.2s ease-in-out

            @media (max-width: 768px)
                padding: 10px
                flex-direction: column !important

            &:hover
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2)
                background: #eff2ff

            .section1
                margin-right: 1rem
                @media (max-width: 768px)
                    margin: 0
                    margin-right: 0 !important
                    width: 100%
                .ytImage
                    height: 8rem
                    @media (max-width: 768px)
                        height: auto
                        width: 100%

            .section2
                width: 100%
                .title
                    display: flex
                    justify-content: space-between
                    margin: 0.3rem 0
                    h2
                        font-size: 1rem
                    .date
                        @media (max-width: 768px)
                            font-size: 0.8rem
                            font-weight: 500
                p
                    @media (max-width: 768px)
                        width: 100%
                        font-size: 0.8rem
                        font-weight: 500
                        word-wrap: break-word
                        
                .ant-btn
                    margin: 0.7rem 0