.pending-page
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 100vw
    height: 100vh

    img
        width: 100%
        max-width: 500px
        margin-bottom: 20px

    p
        font-family: 'Roboto', sans-serif
        color: #00093b
        font-weight: 300
        text-align: center
        margin-bottom: 20px
        width: 80%
        font-size: 24px
    
    .logo
        display: flex
        align-items: center
        justify-content: center
        margin-top: 70px
        img
            margin-top: 16px
            width: 50px
        h1
            font-family: 'Roboto', sans-serif
            color: #00093b
        h2
            font-family: 'Roboto', sans-serif
            color: #00093b
            font-weight: 300
            letter-spacing: 12px
