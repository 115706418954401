.landing-page
    font-family: 'Poppins', sans-serif
    overflow: hidden
    header
        .header-nav-web
            display: flex
            justify-content: space-between
            align-items: center
            padding: 20px 200px
            background-color: #f6f4ff

            @media (max-width: 1100px)
                padding: 20px 100px
            @media (max-width: 800px)
                padding: 20px 50px
            @media (max-width: 768px)
                padding: 20px 20px
                flex-direction: column
                align-items: center
                .logo
                    margin: 0 auto
                    text-align: center
                    margin-bottom: 20px
                .links
                    margin: 0 auto
                    text-align: center
                    margin-bottom: 20px

            .logo
                a
                    text-decoration: none
                    h1
                        color: #12006d
                        font-size: 1.5rem
                        font-weight: 700
                        margin: 0
                    h2
                        color: #665a9e
                        font-weight: 400
                        font-size: 16px
                        letter-spacing: 12px
            .btns
                display: flex
                gap: 20px

                @media (max-width: 768px)
                    flex-direction: column
                    gap: 0px
                    padding: 0
                .ant-btn
                    border-radius: 5px
                    display: flex
                    align-items: center
                    justify-content: center
                    padding: 20px 30px

                .signin
                    background-color: #12006d
                    border: none
                    color: #fff
                    font-size: 1rem
                    font-weight: 600
                    width: 150px
                    height: 50px
                    order: 2
                    @media (max-width: 768px)
                        margin: 10px 0
                        order: 1
                .signup
                    background: none
                    border: none
                    box-shadow: none
                    color: #12006d
                    font-size: 1rem
                    font-weight: 600
                    height: 50px
                    margin: 0
                    padding: 0
                    order: 1
                    @media (max-width: 768px)
                        order: 2
                .translate
                    background: none
                    cursor: pointer
                    box-shadow: none
                    display: flex
                    align-items: center
                    justify-content: center
                    color: #12006d
                    font-size: 1rem
                    font-weight: 600
                    height: 50px
                    margin: 0
                    padding: 0
                    order: 3
                    @media (max-width: 768px)
                        order: 1
    .body
        .main-hero
            display: flex
            justify-content: center
            align-items: center
            flex-direction: column
            background-color: #f6f4ff
            padding: 50px 200px 0 200px

            @media (max-width: 1100px)
                padding: 50px 100px
            @media (max-width: 800px)
                padding: 50px 50px
            @media (max-width: 768px)
                padding: 20px 20px

            .hero-img
                width: 500px
                img
                    width: 100%
                @media (max-width: 768px)
                    width: 300px
            h1
                font-size: 2.5rem
                font-weight: 600
                width: 50%
                text-align: center
                margin: 20px 0

                @media (max-width: 1100px)
                    width: 80%
                @media (max-width: 800px)
                    font-size: 2rem
                    margin-top: 0
                @media (max-width: 768px)
                    font-size: 1.5rem
                    margin-top: 0
                    width: 90%
            h3
                font-size: 1.2rem
                font-weight: 400
                width: 80%
                text-align: center
                margin: 10px 0
                color: #727272

                @media (max-width: 1100px)
                    width: 90%
                @media (max-width: 800px)
                    font-size: 1rem
                    margin-top: 0
                @media (max-width: 768px)
                    font-size: 1rem
                    margin-top: 0
                    width: 90%
            .hero-btns
                display: flex
                justify-content: center
                align-items: center
                padding: 10px 0
                gap: 20px

                @media (max-width: 768px)
                    flex-direction: column
                    gap: 0px
                    padding: 0

                .ant-btn
                    border-radius: 10px
                    display: flex
                    align-items: center
                    justify-content: center
                    padding: 20px 30px
                    margin: 20px 0
                    font-size: 1.2rem
                    font-weight: 600
                    width: 200px
                    height: 60px
                    border: none

                    @media (max-width: 768px)
                        width: 200px
                        margin: 10px 0
                        padding: 10px 0
                        font-size: 1rem
                        height: 50px
                        border-radius: 5px

        .section
            margin: 100px  0 0 0
            @media (max-width: 1100px)
                margin: 50px 0 0 0
            h2
                font-size: 2rem
                font-weight: 600
                text-align: center
                margin: 20px 20px 0 20px
                @media (max-width: 1100px)
                    font-size: 1.5rem
            h3
                font-size: 1.2rem
                font-weight: 400
                text-align: center
                color: #727272
                margin: 0
                padding: 0 100px
                @media (max-width: 1100px)
                    font-size: 1rem
                    padding: 0 50px
                
        .sesction-one
            .section-one-cards
                display: flex
                justify-content: center
                align-items: center
                flex-wrap: wrap
                gap: 50px
                padding: 50px 200px
                @media (max-width: 1100px)
                    padding: 50px 100px
                @media (max-width: 800px)
                    padding: 20px 20px
                    gap: 20px
                .card
                    width: 300px
                    height: 300px
                    border-radius: 20px
                    display: flex
                    flex-direction: column
                    padding: 20px
                    @media (max-width: 1100px)
                        margin: 0 auto
                        align-items: center
                    .card-img
                        height: 100px
                        display: flex
                        justify-content: start
                        align-items: bottom
                        margin-bottom: 20px

                        @media (max-width: 1300px)
                            justify-content: center
                            margin: 0 auto
                        img
                            width: 80px
                            height: 80px
                    h1
                        font-size: 1.2rem
                        font-weight: 600
                        margin: 10px 0
                        @media (max-width: 1300px)
                            text-align: center
                    p
                        font-size: 1rem
                        font-weight: 400
                        color: #727272
                        margin: 0
                        margin-bottom: 20px
                        @media (max-width: 1300px)
                            text-align: center
                    h5
                        font-size: 1rem
                        font-weight: 600
                        margin: 0
                        @media (max-width: 1300px)
                            text-align: center
                    a
                        text-decoration: none
                        color: #747cb5
                        font-size: 1rem
                        font-weight: 600
                        @media (max-width: 1300px)
                            text-align: center
                            margin: 0 auto
        .flex-cont
            display: flex
            justify-content: space-between
            gap: 300px
            padding: 0px 300px
            @media (max-width: 1700px)
                gap: 50px
                padding: 0px 300px
            @media (max-width: 1300px)
                gap: 50px
                padding: 0px 200px
            @media (max-width: 1100px)
                gap: 50px
                padding: 0px 100px
            @media (max-width: 900px)
                gap: 50px
                padding: 0px 50px
            @media (max-width: 800px)
                gap: 50px
                padding: 0px 20px
            @media (max-width: 768px)
                gap: 50px
                padding: 0px 50px
                flex-direction: column
                
            .image
                min-width: 500px
                max-width: 700px
                @media (max-width: 1700px)
                    min-width: 200px
                    max-width: 300px
                @media (max-width: 800px)
                    min-width: 100px
                    max-width: 200px
                @media (max-width: 768px)
                    min-width: 200px
                    max-width: 300px
                    margin: 0 auto
                
                img
                    width: 100%
            .text
                padding: 0
                margin: 0
                display: flex
                justify-content: center
                flex-direction: column
                align-items: flex-start
                text-align: start

                h2
                    margin: 20px 0
                    text-align: start
                    @media (max-width: 768px)
                        text-align: center
                h3
                    margin: 0
                    text-align: start
                    padding: 0
                    @media (max-width: 768px)
                        text-align: center
        .section-three
            .left
                order: 1
                @media (max-width: 768px)
                    order: 2
            .right
                order: 2
                @media (max-width: 768px)
                    order: 1
        .section-four
            .section-four-cards
                display: flex
                justify-content: center
                align-items: center
                flex-wrap: wrap
                gap: 50px
                margin: 50px 0 0 0
                @media (max-width: 800px)
                    margin : 20px 0 0 0
                .card
                    width: 300px
                    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04)
                    cursor: pointer
                    transition: all 0.3s ease-in-out
                    &:hover
                        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2)
                        transform: scale(1.05)

                    
                    .card-image
                        display: flex
                        justify-content: center
                        align-items: center
                        margin-bottom: 20px
                        background-color: #ffdd00
                        padding: 0 100px

                        img
                            padding: 30px
                            width: 300px
                    .img1
                    .img2
                        background-color: #005eff
                    .img3
                        background-color: #ff005e
                    .img4
                        background-color: #00ff9c
                    .card-content
                        padding: 0 20px 10px 20px
                        h1
                            font-size: 1.2rem
                            font-weight: 600
                            margin: 10px 0
                        p
                            font-size: 1rem
                            font-weight: 400
                            color: #727272
                            margin: 0
                            margin-bottom: 20px
                        a
                            text-decoration: none
                            color: #747cb5
                            font-size: 1rem
                            font-weight: 600

        .section-six
            margin: 0 300px
            border-radius: 10px
            padding: 100px 0

            @media (max-width: 1100px)
                margin: 50px 100px
            @media (max-width: 800px)
                margin: 50px 50px
            @media (max-width: 768px)
                margin: 20px 20px
                padding: 50px 0

            h3
                margin: 10px 0
                a
                    font-size: 1.5rem
                    text-decoration: none
                    color: #747cb5

    footer
        margin-top: 70px
        background-color: #ecf5ffaa
        padding: 50px 300px
        display: flex
        justify-content: space-between

        @media (max-width: 1100px)
            padding: 50px 100px
        @media (max-width: 800px)
            padding: 50px 50px
        @media (max-width: 768px)
            padding: 50px 20px
            flex-direction: column
            text-align: center
            .logo
                margin: 0 auto
                text-align: center
                margin-bottom: 20px
            .links
                margin: 0 auto
                text-align: center
                margin-bottom: 20px

        div
            width: 100%
            .logo
                    a
                        text-decoration: none
                        h1
                            color: #12006d
                            font-size: 1.5rem
                            font-weight: 700
                            margin: 0
                        h2
                            color: #665a9e
                            font-weight: 400
                            font-size: 16px
                            letter-spacing: 12px
            .text
                width: 100%
                @media (max-width: 768px)
                    margin-bottom: 20px
            section
                width: 200px
                margin: 0 auto
                .links
                    display: flex
                    flex-direction: column
                    cursor: pointer
                    a
                        text-decoration: none
                        color: #747cb5
                        font-size: 1rem
                        font-weight: 600
                        margin: 5px 0
                        &:hover
                            color: #12006d
        .footer-sec
            p
                margin: 5px 0
                span
                    margin-right: 10px
                a
                    text-decoration: none
                    color: #000
                    font-size: 1rem
                    margin: 5px 0
                    &:hover
                        color: #12006d
    .copy-right
        border-top: 1px solid #d9d9d9
        background-color: #ecf5ffaa
        text-align: center
        padding: 20px 0
        @media (max-width: 768px)
            padding: 20px 20px
            font-size: 12px
        p
            a
                text-decoration: none
                color: #4c517d
                font-size: 1rem
                font-weight: 500
                margin: 5px 0
                &:hover
                    color: #12006d

.register-model
    font-family: 'Poppins', sans-serif
    .reg-modal
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column
        line-height: 1.8

        img
            width: 300px
            margin-bottom: 20px

            @media (max-width: 768px)
                width: 200px
                margin-bottom: 20px

        h1
            font-size: 1.4rem
            font-weight: 600
            margin: 30px 0 20px 0
            line-height: 1.2
            text-align: center
            color: #12006d

            @media (max-width: 768px)
                font-size: 1.2rem
                margin: 20px 0 10px 0

        h4
            font-size: 1rem
            font-weight: 400
            margin: 0
            color: #3c3c3c
            text-align: center

            @media (max-width: 768px)
                font-size: 0.9rem
                margin: 0
                color: #3c3c3c
                text-align: center

            span
                color: #524a7c
                font-weight: 600
                a
                    text-decoration: none
                    color: #524a7c
                    font-weight: 600
                    &:hover
                        color: #12006d
        h5
            font-size: 1.1rem
            font-weight: 500
            margin: 20px 0 5px 0
            color: #727272
            text-align: center

            @media (max-width: 768px)
                font-size: 1rem
                margin: 10px 0 5px 0
                color: #727272
                text-align: center

        .reg-modal-btns
            display: flex
            margin: 20px 0 20px 0
            justify-content: center
            align-items: center
            gap: 10px

            @media (max-width: 768px)
                flex-direction: column
                gap: 0px
                padding: 0
                margin: 10px 0 10px 0

            .ant-btn
                border-radius: 5px
                display: flex
                align-items: center
                justify-content: center
                padding: 10px 30px
                font-size: 1rem
                font-weight: 600
                width: 200px
                height: 40px

                @media (max-width: 768px)
                    margin: 10px 0
                    padding: 10px 0
                    font-size: 1rem
                    height: 40px
            .whtsapp-btn
                background-color: #12006d
                border: none
                color: #fff
            .call-btn
                background: none
                border: 1px solid #12006d
                box-shadow: none
                color: #12006d