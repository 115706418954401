.adminSider
    z-index: 1000
    position: static

    @media (max-width: 768px)
        position: fixed !important
        height: 100vh !important
        
    .sliderTop
        .logo
            margin: 20px 0 10px 0
            display: flex
            align-items: center
            justify-content: center
            a
                font-family: 'Poppins', sans-serif
                text-decoration: none
                h1
                    color: #e8e3ff
                    font-size: 1.2rem
                    font-weight: 700
                    margin: 0
                    line-height: 1
                h2
                    color: #c1b8ec
                    font-weight: 400
                    font-size: 10px
                    letter-spacing: 12px

        img
            width: 100%
            height: 100%
            object-fit: contain

.user-icon-details
    display: flex
    align-items: center
    justify-content: center
    height: 50px
    
    .user-details
        height: 100%
        line-height: 20px
        text-align: end
        display: flex
        flex-direction: column
        justify-content: center
        padding: 0 10px
    .user-icon
        cursor: pointer
        &:hover
            opacity: 0.8
            transition: 0.3s ease-in-out all
            scale: 1.1
            
        
.ant-layout-footer
    text-align: center

    a
        color: #00023393
        font-size: 0.8rem
        font-weight: 500
        text-decoration: none
        &:hover
            color: #00023393
            text-decoration: underline
    @media (max-width: 768px)
        height: 20px !important
        font-size: 0.6rem !important
        margin-bottom: 10px

.logout-page
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    h2
        margin-bottom: 20px
        color: #00023393
    .tips
        display: flex
        flex-direction: column
        width: 100%
        gap: 10px
        h3
            color: #00023393
            font-size: 1.2rem
            font-weight: 600
            margin-bottom: 10px
        .ant-tag
            widows: 100%
            padding: 5px 10px
