.video-frame
    .video-header
        height: 64px !important
        
        @media (max-width: 900px)
            height: 40px !important
            padding: 0 10px !important
        p
            font-size: 16px !important
            @media (max-width: 900px)
                font-size: 12px !important
                line-height: 1.2 !important
        .channel-title
            @media (max-width: 900px)
                display: none !important

    .skip-container
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        display: flex
        align-items: center
        justify-content: center
        z-index: 9999

        .skip-left
            position: absolute
            top: 0
            left: 0
            width: 40%
            height: 100%
            display: flex
            align-items: center
            justify-content: center
            cursor: pointer

            .skip-left-text
                cursor: pointer
                font-size: 20px
                color: #fff
                user-select: none // chrome and Opera
                -moz-user-select: none // Firefox
                -webkit-text-select: none // IOS Safari
                -webkit-user-select: none // Safari

        .skip-center
            position: absolute
            top: 0
            left: 40%
            width: 20%
            height: 100%
            display: flex
            align-items: center
            justify-content: center
            cursor: pointer

            .skip-center-text
                cursor: pointer
                font-size: 20px
                color: #fff
                user-select: none // chrome and Opera
                -moz-user-select: none // Firefox
                -webkit-text-select: none // IOS Safari
                -webkit-user-select: none // Safari

        .skip-right
            position: absolute
            top: 0
            right: 0
            width: 40%
            height: 100%
            display: flex
            align-items: center
            justify-content: center
            cursor: pointer

            .skip-right-text
                cursor: pointer
                font-size: 20px
                color: #fff
                user-select: none // chrome and Opera
                -moz-user-select: none // Firefox
                -webkit-text-select: none // IOS Safari
                -webkit-user-select: none // Safari
        


    .video-player-div
        height: calc( 100vh - 144px - 64px ) !important
        width: 100vw

        @media (max-width: 900px)
            height: calc( 100vh - 100px - 40px ) !important
        .video-player
            height: 100% !important
            width: 100% !important
            @media (max-width: 900px)
                height: calc( 100vh - 40px ) !important
    .video-footer
        height: 144px !important
        background: #fff
        z-index: 9999
        
        @media (max-width: 900px)
            padding: 0 15px !important
            height: 100px !important
        
        .video-controls-new
            @media (max-width: 900px)
                padding: 0 !important
                margin: 0 !important
                transform: translateY( -10px )
            .volume-controls-new
                display: flex
                align-items: center
                justify-content: start
                width: 200px
                order: 1
                @media (max-width: 900px)
                    padding: 0 !important
                    margin: 0 !important
                    width: 100px
                .ant-btn
                    @media (max-width: 900px)
                        padding: 4px 2px !important
            .play-pause-new
                display: flex
                align-items: center
                justify-content: center
                width: 200px
                order: 2
                @media (max-width: 900px)
                    padding: 0 !important
                    margin: 0 !important
                    width:50px

            .full-screen-new
                display: flex
                align-items: center
                justify-content: end
                width: 200px
                order: 3
                @media (max-width: 900px)
                    padding: 0 !important
                    margin: 0 !important
                    width: 100px
                .ant-btn
                    span
                        @media (max-width: 900px)
                            font-size: 16px !important

    .hide-header
        display: none !important
    .hide-footer
        display: none !important
    .max-player
        height: calc( 100vh - 5px ) !important
    
    .ant-float-btn-circle
        z-index: 99999

    .show-control-footer
        display: block !important
    .show-control-player
        height: calc( 100vh - 144px ) !important
        @media (max-width: 900px)
            height: calc( 100vh - 100px ) !important
    
.notification-rotate
    display: flex
    align-items: center
    justify-content: center
    gap: 20px
    
.ant-popover
    z-index: 9999 !important