.signin-page
    width: 100vw
    height: 100vh
    // border: 1px solid red
    .ant-row
        .signup-left
            height: 100vh
            background-color: #ffffffcc
            width: 40%

            @media screen and (max-width: 768px)
                height: 50vh
                width: 100%
        img
            width: 100%
            height: 100%
            object-fit: cover

        .bg
            width: 100%
            height: 100vh
            position: absolute
            top: 0
            left: 0
            z-index: -1
            @media screen and (max-width: 768px)
                height: 50vh

        .logo
            margin: 1rem 0 -3rem 1rem
            a
                text-decoration: none
                h1
                    color: #12006d
                    font-size: 1.5rem
                    font-weight: 700
                    margin: 0
                h2
                    color: #665a9e
                    font-weight: 400
                    font-size: 16px
                    letter-spacing: 12px
        .texts
            color: white
            font-size: 1.5em
            font-weight: bold
            margin: 4em auto
            text-align: center
            width: fit-content
            color: #00002fb3

            h3
                font-size: 1.4em
                font-weight: 400
                text-align: center

                @media (max-width: 1100px)
                    font-size: 1.2em
            h1
                font-size: 2.5em
                font-weight: 600
                text-align: center

                @media (max-width: 1100px)
                    font-size: 2em
            h4
                font-size: 0.8em
                font-weight: 400
                text-align: end

                @media (max-width: 1100px)
                    text-align: center
            h5
                font-size: 1em
                margin-top: 2em
                font-weight: 400
                text-align: center

                @media (max-width: 1100px)
                    font-size: 0.8em
        .image
            width: 100%
            display: flex
            justify-content: center
            align-items: center
            img 
                width: 300px
                object-fit: cover

            @media screen and (max-width: 768px)
                display: none
                
        .signup-right
            padding: 3em 15% 0 15%
            width: 60%
            height: 100vh
            overflow: scroll
            margin: 0
            display: flex
            flex-direction: column
            justify-content: center

            @media screen and (max-width: 768px)
                padding: 3em 1em 0 1em
                width: 100% !important
                overflow: visible
                height: 100%
                margin-bottom: 2em
                
            .ant-row
                width: 100%
                .row-col
                    width: 50%
                    @media screen and (max-width: 768px)
                        width: 100%
                    .ant-col
                        padding: 0 1em
                        @media screen and (max-width: 768px)
                            padding: 0 0.5em


            .ant-col
                @media screen and (max-width: 768px)
                    padding: 0 1em
                    width: 100%

            h1
                font-size: 2em
                font-weight: 500
                text-align: center
                margin: 1em 0

                @media screen and (max-width: 480px)
                    margin-top: -1em



            .submit-button
                margin: 0
                padding: 0
                .ant-col
                    display: flex
                    justify-content: center
                    align-items: center
            .form-bottom
                width: 100%
                display: flex
                justify-content: space-between
                align-items: center
                border: 1px solid red

            .sign-in
                line-height: 0em
                margin: 0 
                padding: 0.5em
                h1
                    font-size: 1em
                    font-weight: 400
                    text-align: center
                    margin: 0
                    a
                        font-weight: 800
                        color: #00002fb3
                        cursor: pointer
                
                @media screen and (max-width: 1000px)
                    h1
                        font-size: 0.9em

        .email-verify
            padding: 3em 15% 0 15%
            width: 60%
            height: 100vh
            overflow: scroll
            margin: 0
            display: flex
            flex-direction: column
            justify-content: center
            
            @media screen and (max-width: 768px)
                padding: 3em 1em 0 1em
                width: 100% !important
                overflow: visible
                height: 100%
                margin-bottom: 2em
            
            @media screen and (max-width: 480px)
                overflow-x: hidden !important
                padding: -6em 1em 5em 1em
                width: 100% !important
                overflow: visible
                height: 80%
                margin-bottom: 2em
                margin-top: -6em

            .oops
                width:100%
                display: flex
                flex-direction: column
                justify-content: center
                align-items: center
                margin-top: 1rem
                margin-left: 50%
                margin: auto

                @media screen and (max-width: 480px)
                    margin-top: 3rem
                
                
                h2
                    font-size: 1.5em
                    font-weight: 400
                    text-align: center
                    margin: 1em auto
                    color: #17172c
            p
                font-size: 1.2em
                font-weight: 300
                text-align: center
                margin: 1em auto
                color: #000036d3
            h3
                font-size: 1.2em
                font-weight: 300
                text-align: center
                margin: 1em auto
                color: #000036d3

